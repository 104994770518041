import React, { useState } from "react";
import { Button } from "react-bootstrap";

import "./MainNav.scss";
import ContactOffCanvas from "./ContactOffCanvas";

const MainNav = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div id="MainNavContainer">
      <div className="appBrand">
        <p className="brand-title">New Orleans Sound Emporium</p>
        <p className="brand-tagline">Record Label & Creative Center</p>
        <p className="brand-location">Nashville • New Orleans</p>
      </div>

      <Button
        id="contact-button"
        className="ms-3"
        size="sm"
        variant="primary"
        onClick={handleShow}
      >
        Contact
      </Button>

      <ContactOffCanvas show={show} handleClose={handleClose} />
    </div>
  );
};

export default MainNav;
