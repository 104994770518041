import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

// Keyframes for random height and opacity animation
const randomHeightAndOpacity = keyframes`
  0%, 100% { 
    height: 20%; 
    opacity: 0;
  }
  50% { 
    height: 60%; 
    opacity: 1;
  }
`;

// Styled component for the wrapper
const RainbowCanvas = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 50;
  background: transparent;

  @media (max-width: 768px) {
    height: 75%;
    top: 10%;
  }
`;

// Styled component for each bar
const SoundBar = styled.div<{ delay: number; color: string }>`
  width: 10px;
  margin: 0 2px;
  background-color: ${({ color }) => color};
  animation: ${randomHeightAndOpacity} 6s ease-in-out infinite; /* Slower animation (4s duration) */
  animation-delay: ${({ delay }) => delay}s;
`;

const RainbowSoundBars: React.FC = () => {
  const [bars, setBars] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const colors = [
      "#4B0082", // Indigo
      "#483D8B", // Dark Slate Blue
      "#6A5ACD", // Slate Blue
      "#7B68EE", // Medium Slate Blue
      "#9370DB", // Medium Purple
      "#8A2BE2", // Blue Violet
      "#9400D3", // Dark Violet
      "#8B008B", // Dark Magenta
      "#800080", // Purple
      "#0000CD", // Medium Blue
      "#00008B", // Dark Blue
      "#191970", // Midnight Blue
    ];

    const newBars = Array.from({ length: 30 }, (_, i) => (
      <SoundBar
        key={i}
        color={colors[i % colors.length]}
        delay={Math.random() * 3} // Random delay for each bar (up to 4s)
      />
    ));
    setBars(newBars);
  }, []);

  return <RainbowCanvas>{bars}</RainbowCanvas>;
};

export default RainbowSoundBars;
