import React from "react";
import { Offcanvas } from "react-bootstrap";
import "./ContactOffCanvas.scss";

interface VocalsOffCanvasProps {
  show: boolean;
  handleClose: () => void;
}

const VocalsOffCanvas: React.FC<VocalsOffCanvasProps> = ({
  show,
  handleClose,
}) => {
  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="ContactUsTitle">
          Vocal Production
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="ContactUsTitleDescContainer">
          <img
            style={{ marginBottom: "50px" }}
            width={"100%"}
            src="sing.webp"
            alt="phone"
          />
          <h6 className="ContactUsTitleDesc">
            You can learn how to reach your best singing potential. This center
            is open to all ages and skill levels with availability online or in
            person.
          </h6>
          <hr />

          <p className="ContactUsPhone">
            <a className="ContactUsPhoneLink" href="tel:5044537471">
              504.453.7471
            </a>
          </p>
          <p className="ContactUsEmail">
            <a
              className="ContactUsEmailLink"
              href="mailto:neworleanssoundemporium@gmail.com"
            >
              neworleanssoundemporium@gmail.com
            </a>
          </p>
          <hr />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default VocalsOffCanvas;
