import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./MainFooter.scss";

const MainFooter = () => {
  return (
    <div id="mainFooter">
      <Container fluid>
        <Row>
          <Col>
            <p className="footerText">
              <a href="tel:+15044537471">
                <strong>504.453.7471</strong>
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainFooter;
