import React, { useEffect, useRef } from "react";
import "./SoundWaveCircle.scss";

const SoundWaveCircle: React.FC = () => {
  const pathRef = useRef<SVGPathElement>(null);
  const animationTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    animateWave();
    return () => {
      if (animationTimer.current) clearInterval(animationTimer.current);
    };
  }, []);

  const animateWave = () => {
    const animate = () => {
      const path = pathRef.current;
      if (path) {
        path.setAttribute("d", generateWavePath());
      }
    };

    animationTimer.current = setInterval(animate, 500); // Adjust the animation speed here
  };

  const generateWavePath = () => {
    const r = 90;
    const cx = 100;
    const cy = 100;
    const points = 128; // Increase the number of points
    const angleStep = (2 * Math.PI) / points;

    const pointsArray = [];
    for (let i = 0; i <= points; i++) {
      const angle = i * angleStep;
      const factor = 1 + 0.05 * Math.sin(i * 0.5 + Date.now() / 1000); // Add Date.now() for animation effect
      const x = cx + r * factor * Math.cos(angle);
      const y = cy + r * factor * Math.sin(angle);
      pointsArray.push({ x, y });
    }

    let d = `M ${pointsArray[0].x} ${pointsArray[0].y}`;
    for (let i = 1; i < pointsArray.length; i++) {
      const cp1 = pointsArray[i - 1];
      const cp2 = pointsArray[i];
      const cp1Control = {
        x: (cp1.x + cp2.x) / 2,
        y: (cp1.y + cp2.y) / 2,
      };
      d += ` Q ${cp1.x} ${cp1.y}, ${cp1Control.x} ${cp1Control.y}`;
    }

    d += " Z";
    return d;
  };

  return (
    <div className="background-svg">
      <svg
        viewBox="0 0 200 200"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: "#37214a", stopOpacity: 1 }}
            />
            <stop
              offset="50%"
              style={{ stopColor: "#6e3b8f", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#a865cc", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <path
          ref={pathRef}
          d={generateWavePath()}
          fill="none"
          stroke="url(#grad1)"
          strokeWidth="0.5" // Thinner stroke for a more subtle effect
          className="sound-wave"
        />
      </svg>
    </div>
  );
};

export default SoundWaveCircle;
