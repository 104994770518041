import React from "react";
import { Offcanvas } from "react-bootstrap";
import "./ContactOffCanvas.scss";

interface LessonsOffCanvasProps {
  show: boolean;
  handleClose: () => void;
}

const LessonsOffCanvas: React.FC<LessonsOffCanvasProps> = ({
  show,
  handleClose,
}) => {
  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="ContactUsTitle">
          Start Lessons
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="ContactUsTitleDescContainer">
          <img
            style={{ marginBottom: "50px" }}
            width={"100%"}
            src="nolase-lessons-contact.webp"
            alt="phone"
          />
          <h6 className="ContactUsTitleDesc">
            You can overcome your fears in music. We offer lessons for all ages
            and skill levels through one on one sessions.
          </h6>
          <hr />

          <p className="ContactUsPhone">
            <a className="ContactUsPhoneLink" href="tel:5044537471">
              504.453.7471
            </a>
          </p>
          <p className="ContactUsEmail">
            <a
              className="ContactUsEmailLink"
              href="mailto:neworleanssoundemporium@gmail.com"
            >
              neworleanssoundemporium@gmail.com
            </a>
          </p>
          <hr />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default LessonsOffCanvas;
