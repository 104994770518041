import React, { ReactNode, useState } from "react";
import "./App.scss";
import { Container, Carousel, Row, Col, Button, Card } from "react-bootstrap";
import MainNav from "./componenets/MainNav";
import MainFooter from "./componenets/MainFooter";
import SoundWaveCircle from "./componenets/SoundWaveCircle";
import RainbowBackdrop from "./componenets/RainbowBackdrop";
import ContactOffCanvas from "./componenets/ContactOffCanvas";
import LessonsOffCanvas from "./componenets/LessonsOffCanvas";
import VocalsOffCanvas from "./componenets/VocalsOffCanvas";

interface Slide {
  title: ReactNode;
  img: string;
  mobileImg: string;
  description: ReactNode;
}

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showLessons, setShowLessons] = useState(false);

  const handleCloseLessons = () => setShowLessons(false);
  const handleShowLessons = () => setShowLessons(true);

  const [showVocals, setShowVocals] = useState(false);

  const handleCloseVocals = () => setShowVocals(false);
  const handleShowVocals = () => setShowVocals(true);

  const HomeSlides: Slide[] = [
    {
      title: (
        <h1 className="MainTitle">
          Multi Genre Record Label <br /> & Learning Center
        </h1>
      ),
      img: "nolase.webp",
      mobileImg: "nolase-mobile.webp",
      description: (
        <>
          <p className="mainSlideDescription">
            In person sessions are available in Nashville & New Orleans for
            beginners and professionals.
            <span className="mainSlideDescriptionSecond">
              We help musicians to learn, grow, excel, start recording, and get
              bookings. We offer online music lessons for those who do not live
              near our in person locations.
            </span>
          </p>
        </>
      ),
    },
    {
      title: "Vocal Production",
      img: "nolase-recording-sessions.webp",
      mobileImg: "nolase-recording-sessions-mobile.webp",
      description: (
        <>
          <p>
            You can book a session with a singing specialist that has worked
            with beginners and Grammy winners.
          </p>
          <Button
            className="slideBtn float-end"
            size="sm"
            variant="primary"
            onClick={handleShowVocals}
          >
            Lets Go!
          </Button>
        </>
      ),
    },
    {
      title: "Events",
      img: "nolase-events.webp",
      mobileImg: "nolase-events-mobile.webp",
      description: (
        <>
          <p>
            Do you need a Singer or Band for your Venue or Event? Contact us!
          </p>
          <Button
            className="slideBtn float-end"
            size="sm"
            variant="primary"
            onClick={handleShow}
          >
            Contact Us
          </Button>
        </>
      ),
    },
    {
      title: "Lessons",
      img: "nolase-lessons.webp",
      mobileImg: "nolase-lessons-mobile.webp",
      description: (
        <>
          <p>
            "We welcome all ages online or in person for beginners and
            professionals in voice, guitar, clarinet, piano, & songwriting."
          </p>
          <Button
            className="slideBtn float-end"
            size="sm"
            variant="primary"
            onClick={handleShowLessons}
          >
            Learn More
          </Button>
        </>
      ),
    },
    {
      title: "Creative Director",
      img: "kit-thorn.webp",
      mobileImg: "kit-thorn-mobile.webp",
      description: (
        <>
          <p>
            Do you need help on how to book a gig? Schedule a session with
            singer, writer, and producer Kit Thorn.
          </p>
          <Button
            className="slideBtn float-end"
            size="sm"
            variant="primary"
            onClick={handleShow}
          >
            Schedule Here
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container className="skyGradientBackground" fluid>
      <MainNav />
      <RainbowBackdrop />
      <ContactOffCanvas show={show} handleClose={handleClose} />
      <LessonsOffCanvas show={showLessons} handleClose={handleCloseLessons} />
      <VocalsOffCanvas show={showVocals} handleClose={handleCloseVocals} />
      <Row>
        <Col id="homeSlider" md={12}>
          <SoundWaveCircle />
          <Carousel
            className="home-carousel"
            interval={null}
            indicators={false}
            pause="hover"
            data-bs-theme="dark"
          >
            {HomeSlides.map((slide, i) => {
              return (
                <Carousel.Item
                  key={i}
                  className={
                    i === 0
                      ? "theme-carousel-item main-item"
                      : "theme-carousel-item"
                  }
                >
                  <div className="slide-image">
                    <picture>
                      <source media="(min-width: 768px)" srcSet={slide.img} />
                      <source
                        media="(max-width: 767px)"
                        srcSet={slide.mobileImg}
                      />
                      <img
                        className="d-block w-100"
                        src="https://via.placeholder.com/800x400" // Fallback for browsers that do not support picture element
                        alt="First slide"
                      />
                    </picture>
                  </div>
                  {i === 0 ? (
                    <>
                      <h5 className="mainSlideTitle">{slide.title}</h5>
                      {slide.description}
                    </>
                  ) : (
                    <>
                      <h5 className="slideTitle">{slide.title}</h5>
                      <Card bg="light" text="dark" className="slideCard">
                        <Card.Body>
                          <Card.Text>{slide.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
      </Row>

      <MainFooter />
    </Container>
  );
}

export default App;
