import React from "react";
import { Offcanvas } from "react-bootstrap";
import "./ContactOffCanvas.scss";

interface ContactOffCanvasProps {
  show: boolean;
  handleClose: () => void;
}

const ContactOffCanvas: React.FC<ContactOffCanvasProps> = ({
  show,
  handleClose,
}) => {
  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="ContactUsTitle">Contact Us</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <img
          style={{ position: "absolute", left: 50, top: 150 }}
          width={"70%"}
          src="phone-rotary.webp"
          alt="phone"
        />
        <div className="ContactUsTitleDescContainer">
          <h6 className="ContactUsTitleDesc">
            If you are interested in bookings or services you can call, text, or
            email.
          </h6>
          <hr />

          <p className="ContactUsPhone">
            <a className="ContactUsPhoneLink" href="tel:5044537471">
              504.453.7471
            </a>
          </p>
          <p className="ContactUsEmail">
            <a
              className="ContactUsEmailLink"
              href="mailto:neworleanssoundemporium@gmail.com"
            >
              neworleanssoundemporium@gmail.com
            </a>
          </p>
          <hr />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ContactOffCanvas;
